import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap);

import '../app.scss';
