<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
        <b-alert
            v-model="updateExists"
            class="position-fixed fixed-bottom m-0 rounded-0"
            style="z-index: 2000;"
            variant="info"
        >
            <h4 class="alert-heading">An update is available</h4>
            <b-button @click="refreshApp">Update</b-button>
        </b-alert>
    </div>
</template>

<script>
import update from './mixins/update';
const default_layout = 'default';

export default {
    name: 'App',
    mixins: [update],
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + '-layout';
        }
    }
};
</script>
