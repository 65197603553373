<template>
    <div id="app">
        <nav-header />
        <b-row class="setting-conatiner">
            <b-col md="1" class="side-rail">
                <b-sidebar
                    id="sidebar-menu"
                    no-header
                    shadow
                    :backdrop="$screen.lt.sm"
                    :no-close-on-route-change="$screen.gt.sm"
                    no-close-on-esc
                    :visible="visible"
                    sidebar-class="score-side"
                >
                    <b-list-group class="mb-4">
                        <b-list-group-item :to="{ name: 'Home' }">My Scorecard</b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="isOrgAdmin" class="mb-4">
                        <b-list-group-item :to="{ name: 'Organization' }">My Organization</b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="isOrgManager" class="mb-4">
                        <b-list-group-item :to="{ name: 'TeamDashboard' }">My Team</b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="isOrgAdmin && getRoles.length" class="mb-4">
                        <b-list-group-item :to="{ name: 'Roles' }">By Roles</b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="isOrgAdmin" class="mb-4">
                        <b-list-group-item :to="{ name: 'Groups' }">By Groups</b-list-group-item>
                    </b-list-group>
                    <template v-if="getTeam.length">
                        <div class="setting-heading">
                            Team Members
                        </div>
                        <b-list-group class="mb-4">
                            <b-list-group-item
                                v-for="(member, index) in getTeam"
                                :key="index"
                                :to="{ name: 'UserDashboard', params: { userId: member.user.id } }"
                            >
                                {{ member.user.firstName }}
                            </b-list-group-item>
                        </b-list-group>
                    </template>
                </b-sidebar>
            </b-col>
            <b-col md="11" sm="12" class="main">
                <div class="container-fluid score-container" :key="$route.path">
                    <slot />
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NavHeader from '@/components/NavHeader';

export default {
    components: {
        NavHeader
    },
    computed: {
        ...mapGetters(['getTeam', 'getRoles', 'isOrgAdmin', 'isOrgManager'])
    },
    data() {
        return {
            visible: this.$screen.gt.sm
        };
    },
    mounted() {
        this.$store.dispatch('fetchTeam');
        this.$store.dispatch('fetchRoles');
    }
};
</script>
<style lang="scss">
.score-side {
    top: 4rem;
    height: calc(100vh - 4rem);
    width: 280px;
}
</style>
<style lang="scss" scoped>
.setting-conatiner {
    margin-left: 0;
    margin-right: 0;
}

.side-rail {
    width: 280px;
    max-width: 280px;
    flex: 0 0 280px;
    /* display: none; */
}
.main {
    top: 4rem;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}

@media only screen and (min-width: 768px) {
    .main {
        max-width: calc(100% - 280px);
        flex: 0 0 calc(100% - 280px);
    }

    .side-rail {
        width: 280px;
        max-width: 280px;
        flex: 0 0 280px;
        display: block;
    }
}

.setting-heading {
    padding: 0.25rem 1.5rem;
    font-weight: 600;
}
</style>
