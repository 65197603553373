import httpClient from '../services';

const state = {
    team: [],
    roles: []
};

const getters = {
    getTeam(state) {
        return state.team;
    },
    getRoles(state) {
        return state.roles;
    }
};

const actions = {
    fetchTeam({ commit, getters }) {
        if (getters.getTeam.length) {
            return Promise.resolve();
        }
        return httpClient.get(`/user/team`).then(response => {
            return commit('setTeam', response.data);
        });
    },
    fetchRoles({ commit, getters }) {
        if (getters.getRoles.length) {
            return Promise.resolve();
        }
        return httpClient.get(`/roles`).then(response => {
            return commit('setRoles', response.data);
        });
    },
    clearProfile({ commit }) {
        commit('setTeam', []);
        commit('setRoles', []);
    }
};

const mutations = {
    setTeam: (state, data) => {
        state.team = data;
    },
    setRoles: (state, data) => {
        state.roles = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
