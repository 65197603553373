<template>
    <b-navbar toggleable="lg" type="dark" variant="info" class="score-nav" fixed="top">
        <b-navbar-toggle target="sidebar-menu"></b-navbar-toggle>
        <b-navbar-brand :to="{ name: 'Home' }">
            <img :src="require('../images/logo.png')" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-if="!isAuthenticated" :to="{ name: 'Register' }">
                    {{ $t('register') }}
                </b-nav-item>
                <b-nav-item v-if="!isAuthenticated" :to="{ name: 'Login' }">
                    {{ $t('signIn') }}
                </b-nav-item>
                <b-nav-item v-if="isAdmin" :to="{ name: 'Organizations' }">
                    <b-icon icon="building"></b-icon> {{ $t('orgSettings') }}
                </b-nav-item>
                <b-nav-item v-if="isOrgAdmin" :to="{ name: 'RoleSettings' }">
                    <b-icon icon="tools"></b-icon> {{ $t('settings') }}
                </b-nav-item>
                <b-nav-item-dropdown v-if="isAuthenticated" right>
                    <template #button-content>
                        {{ currentUser.firstName }}
                    </template>
                    <b-dropdown-text v-if="currentUserOrganization" class="text-nowrap">
                        <b-icon icon="building"></b-icon> {{ currentUserOrganization.name }}
                    </b-dropdown-text>
                    <b-dropdown-item @click="logout">
                        {{ $t('signOut') }}
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'NavHeader',
    computed: {
        ...mapGetters(['currentUser', 'currentUserOrganization', 'isAuthenticated', 'isAdmin', 'isOrgAdmin'])
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push({ name: 'Register' });
        }
    }
};
</script>
<style lang="scss" scoped>
.score-nav {
    min-height: 4rem;
}

.navbar-brand {
    img {
        height: 38px;
    }
}
</style>
