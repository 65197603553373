import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import Auth from './auth';
import Profile from './profile';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth: Auth,
        profile: Profile
    },
    plugins: [
        createPersistedState({
            paths: ['auth']
        })
    ]
});

export default store;
