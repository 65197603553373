import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import './plugins/filters';
import './plugins/luxon';
import './plugins/vuelidate';
import './registerServiceWorker';
import Default from './layouts/Default.vue';
import Settings from './layouts/Settings.vue';
import Profile from './layouts/Profile.vue';
import titleMixin from './mixins/titleMixin';
import router from './router';
import store from './store';

import App from './App.vue';
import './services';
import i18n from './plugins/i18n';
import { state, initBreakpointUtil } from './utils/BreakpointUtil';
initBreakpointUtil();
Vue.mixin(titleMixin);

Vue.component('default-layout', Default);
Vue.component('settings-layout', Settings);
Vue.component('profile-layout', Profile);

Vue.config.productionTip = false;
Vue.prototype.$screen = state.screen;

new Vue({
    router,
    store,
    i18n,
    // computed: {
    //     screen: () => state.screen
    // },
    render: h => h(App)
}).$mount('#app');
