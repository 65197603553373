import httpClient from '../services';
import JwtService from '../services/jwtService';

const state = {
    isAuthenticated: !!JwtService.getToken(),
    user: {},
    userOrganization: {}
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    currentUserOrganization(state) {
        return state.userOrganization;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    isAdmin(state) {
        return state.user && state.user.sitePermission === 0;
    },
    isOrgAdmin(state) {
        return state.userOrganization && state.userOrganization.permissions === 0;
    },
    isOrgManager(state) {
        return state.user && state.userOrganization.permissions <= 5;
    }
};

const actions = {
    login(context, credentials) {
        return httpClient
            .post('users/signin', {
                email: credentials.email,
                password: credentials.password
            })
            .then(response => {
                return context.commit('setAuth', response.data);
            });
    },
    googleLogin(context, accessToken) {
        return httpClient
            .post('users/oauth/google', {
                id_token: accessToken
            })
            .then(response => {
                return context.commit('setAuth', response.data);
            });
    },
    logout(context) {
        context.commit('setLogout');
        context.dispatch('clearProfile');
    },
    register(context, credentials) {
        return new Promise((resolve, reject) => {
            httpClient
                .post('users/signup', {
                    email: credentials.email,
                    firstName: credentials.firstName,
                    lastName: credentials.lastName,
                    password: credentials.password
                })
                .then(response => {
                    context.commit('setAuth', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

const mutations = {
    setAuth: (state, data) => {
        state.isAuthenticated = true;
        state.user = data.user;
        state.userOrganization = data.organization;
        JwtService.saveToken(data.access_token);
    },
    setLogout: state => {
        state.isAuthenticated = false;
        state.user = {};
        state.userOrganization = {};
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
