<template>
    <div id="app">
        <nav-header />
        <b-row class="setting-conatiner">
            <b-col md="1" class="side-rail">
                <b-sidebar
                    id="sidebar-menu"
                    no-header
                    shadow
                    :backdrop="$screen.lt.sm"
                    :no-close-on-route-change="$screen.gt.sm"
                    no-close-on-esc
                    :visible="visible"
                    sidebar-class="score-side"
                >
                    <div class="setting-heading">Scorecard Settings</div>
                    <b-list-group>
                        <b-list-group-item :to="{ name: 'RoleSettings' }">Roles</b-list-group-item>
                        <b-list-group-item :to="{ name: 'GroupSettings' }">Groups</b-list-group-item>
                        <b-list-group-item :to="{ name: 'CategorySettings' }">Scorecards</b-list-group-item>
                    </b-list-group>
                    <div class="setting-heading">Organization Settings</div>
                    <b-list-group>
                        <b-list-group-item :to="{ name: 'UserSettings' }">Users</b-list-group-item>
                    </b-list-group>
                </b-sidebar>
            </b-col>
            <b-col md="11" sm="12" class="main">
                <div class="container-fluid score-container">
                    <slot />
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import NavHeader from '@/components/NavHeader';

export default {
    components: {
        NavHeader
    },
    data() {
        return {
            visible: this.$screen.gt.sm
        };
    }
};
</script>
<style lang="scss">
.score-side {
    top: 4rem;
    height: calc(100vh - 4rem);
    width: 280px;
}
</style>
<style lang="scss" scoped>
.setting-conatiner {
    display: flex;
}
.side-rail {
    width: 280px;
    max-width: 280px;
    flex: 0 0 280px;
    /* display: none; */
}
.main {
    top: 4rem;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .main {
        max-width: calc(100% - 280px);
        flex: 0 0 calc(100% - 280px);
    }

    .side-rail {
        width: 280px;
        max-width: 280px;
        flex: 0 0 280px;
        display: block;
    }
}

.setting-heading {
    padding: 0.25rem 1.5rem;
    font-weight: 600;
}
</style>
