class Formats {
    static currency(priceStr, currency, precision) {
        let display = '$' + parseFloat(priceStr).toFixed(precision || 2);
        if (currency) {
            display += ' ' + currency;
        }
        return display;
    }

    static currencyRounded(priceStr, currency) {
        let display = '$' + Math.ceil(priceStr);
        if (currency) {
            display = display + ' ' + currency;
        }
        return display;
    }

    static number(numStr, decimalPlaces) {
        if (numStr) {
            return parseFloat(numStr).toFixed(decimalPlaces || 2);
        }
        return numStr;
    }

    static toPossessiveName(n) {
        if (!n) {
            return '';
        }
        let name = n;
        if (name.substr(name.length - 1) === 's') {
            name = name + "'";
        } else {
            name = name + "'s";
        }
        return name;
    }

    static toInitials(first, last) {
        let initials;
        initials = first.substring(0, 1).toUpperCase();
        if (last.length > 1) {
            initials += last.substring(0, 1).toUpperCase();
        }
        return initials;
    }

    static toFullName(firstName, middleName, lastName) {
        return this.toCapitalize(firstName).concat(
            ' ',
            this.toCapitalize(middleName),
            ' ',
            this.toCapitalize(lastName),
            ' '
        );
    }

    static toCapitalize(value) {
        if (!value) {
            return '';
        }
        value = value
            .toString()
            .trim()
            .toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    static formatClassName(name) {
        return name
            .trim()
            .toLowerCase()
            .replace(' ', '-');
    }
}

export default Formats;
