import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Profile from '../views/Profile.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Profile,
        meta: {
            requiresAuth: true,
            layout: 'profile'
        },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/profile/MyProfile.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/organization',
                name: 'Organization',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/Organization.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/roles',
                name: 'Roles',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/Roles.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/groups',
                name: 'Groups',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/Groups.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/score/role/:roleId',
                name: 'RoleScorecard',
                props: true,
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/RoleScorecard.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/score/group/:groupId',
                name: 'GroupScorecard',
                props: true,
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/GroupScorecard.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/team',
                name: 'TeamScorecard',
                props: true,
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/profile/Team.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: '/team/score',
                name: 'TeamDashboard',
                props: true,
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/TeamScorecard.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            }
        ]
    },
    {
        path: '/score/:userId',
        component: {
            render(c) {
                return c('router-view');
            }
        },
        meta: {
            requiresAuth: true,
            layout: 'profile'
        },
        children: [
            {
                path: '',
                name: 'UserDashboard',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/UserScorecard.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: ':scorecardId',
                name: 'ViewScorecard',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/Scorecard.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            },
            {
                path: ':scorecardId/edit',
                name: 'EditScorecard',
                component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/EditScorecard.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'profile'
                }
            }
        ]
    },
    {
        path: '/scorecard/:userId/complete',
        name: 'ScorecardComplete',
        component: () => import(/* webpackChunkName: "scorecard" */ '../views/scorecard/CompleteScorecard.vue'),
        meta: {
            requiresAuth: true,
            layout: 'profile'
        }
    },
    {
        path: '/register',
        name: 'Register',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "register" */ '../views/Login.vue')
    },
    {
        path: '/organizations',
        name: 'Organizations',
        component: () => import(/* webpackChunkName: "organization" */ '../views/admin/Organizations.vue'),
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/organizations/:organizationId',
        name: 'EditOrganization',
        component: () => import(/* webpackChunkName: "organization" */ '../views/admin/EditOrganization.vue'),
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        // route level code-splitting
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
            requiresAuth: true,
            layout: 'settings'
        },
        children: [
            {
                path: 'role',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                meta: {
                    requiresAuth: true,
                    layout: 'settings'
                },
                children: [
                    {
                        path: '',
                        name: 'RoleSettings',
                        component: () => import(/* webpackChunkName: "settings" */ '../views/settings/role/Role.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    },
                    {
                        path: ':roleId',
                        name: 'EditRole',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/role/EditRole.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    }
                ]
            },
            {
                path: 'group',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                meta: {
                    requiresAuth: true,
                    layout: 'settings'
                },
                children: [
                    {
                        path: '',
                        name: 'GroupSettings',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/groups/ListGroups.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    },
                    {
                        path: ':groupId',
                        name: 'EditGroupSettings',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/groups/EditGroup.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    }
                ]
            },
            {
                path: 'category',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                meta: {
                    requiresAuth: true,
                    layout: 'settings'
                },
                children: [
                    {
                        path: '',
                        name: 'CategorySettings',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/category/Category.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    },
                    {
                        path: 'add',
                        name: 'AddCategorySettings',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/category/AddCategory.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    },
                    {
                        path: ':categoryId',
                        name: 'EditCategorySettings',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/category/EditCategory.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    }
                ]
            },
            {
                path: 'users',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                // name: 'UserSettings',
                // component: () => import(/* webpackChunkName: "settings" */ '../views/settings/User.vue'),
                meta: {
                    requiresAuth: true,
                    layout: 'settings'
                },
                children: [
                    {
                        path: '',
                        name: 'UserSettings',
                        component: () => import(/* webpackChunkName: "settings" */ '../views/settings/users/User.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    },
                    {
                        path: ':userId',
                        name: 'EditUserSettings',
                        component: () =>
                            import(/* webpackChunkName: "settings" */ '../views/settings/users/EditUser.vue'),
                        meta: {
                            requiresAuth: true,
                            layout: 'settings'
                        }
                    }
                ]
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, _from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.state.auth.isAuthenticated) {
            next({ name: 'Login' });
            return;
        }
    }
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!store.getters['isAdmin']) {
            next({ name: 'Home' });
            return;
        }
    }
    next();
});

export default router;
