export default {
    created() {
        this.updateTitle();
    },
    methods: {
        updateTitle() {
            let title = 'STEPLadder';
            if (
                this.$store &&
                this.$store.state.auth.userOrganization &&
                this.$store.state.auth.userOrganization.name
            ) {
                title = title + ' - ' + this.$store.state.auth.userOrganization.name;
            }
            document.title = title;
        }
    },
    watch: {
        '$store.state.auth.userOrganization': function() {
            this.updateTitle();
        }
    }
};
