import axios from 'axios';
import router from '../router';
import store from '../store';
import { API_URL } from '../common/config';
import JwtService from './jwtService';

const httpClient = axios.create({
    baseURL: API_URL,
    headers: {
        //timeout: 1000
    }
});

const authInterceptor = config => {
    config.headers['Authorization'] = JwtService.getToken();
    return config;
};

const errorInterceptor = error => {
    if (!error.response) {
        console.log(error.response);
        return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
        case 401:
            store.dispatch('logout');
            if (!['Login', 'Register'].includes(router.currentRoute.name)) {
                router.push({ name: 'Login' });
            }
            break;

        default:
            console.error(error.response.status, error.message);
    }
    return Promise.reject(error);
};
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(response => {
    return response;
}, errorInterceptor);

export default httpClient;
