<template>
    <div id="app">
        <nav-header />
        <div>
            <div class="main">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import NavHeader from '@/components/NavHeader';
export default {
    components: {
        NavHeader
    }
};
</script>
<style lang="scss" scoped>
.main {
    position: relative;
    display: flex;
    top: 4rem;
}
</style>
